.custom-modal .modal-dialog {
    width: 420.66px;
    height: 304px;
    opacity: 1;
}

.custom-modal .modal-content {
    gap: 0;
}

.modal-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 50px;
}

.modal-text {
    margin-bottom: 50px;
}