.analytics-container .analytics-heading {
  font-size: 20px;
  color: #000000;
  font-weight: 700;
}
.analytics-chart-container {
  width: 100%;
}
@media (min-width: 1000px) {
  .analytics-chart-container {
    width: 70%; /* 70% width for large screens */
  }
}

.accordion {
  --bs-accordion-active-bg: none !important;
  --bs-accordion-btn-focus-box-shadow: none !important;
  --bs-accordion-bg: none !important;
  scrollbar-width: none !important;
}
.accordion-item {
  border-radius: 4px !important;
  border: 1px solid #d3d3d3 !important;
}
.accordion-header {
  background: #f9f9f9;
}
.accordion-collapse {
  border-top: 1px solid #d3d3d3 !important;
}
.accordion-button {
  color: #1c1d22;
  font-size: 16px;
  font-weight: 400;
}
.level-educator-tabs {
  border-bottom: 1px solid #d3d3d3 !important;
}
.level-educator-tabs span {
  color: #1c1d22 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}
.level-educator-tabs .active-btn span {
  color: #0343fa !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}
.table-container {
  max-height: 50vh;
  height: auto;
  overflow-y: auto;
  scrollbar-width: none;
}

.table-container::-webkit-scrollbar {
  display: none;
}

@media (max-width: 1024px) {
  .table-container {
    max-height: 40vh;
  }
  .correction-table .correction-table-heading,
  .correction-table-cell {
    padding: 2px 7px !important;
  }
}

@media (max-width: 768px) {
  .table-container {
    max-height: 30vh;
  }
  .correction-table .correction-table-heading,
  .table-cell {
    padding: 2px 7px !important;
  }
  .daterange-dropdown {
    margin-bottom: 1rem !important;
  }
  .daterange-dropdown .dropdown-item {
    padding: 10px !important;
  }
}
.daterange-dropdown .dropdown-menu {
  margin-bottom: 6rem;
  border: 1px solid #e4e4e4;
  box-shadow: 0px 6px 12px 0px #0000001f;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #1c1d22;
  border: none !important;
}

.daterange-dropdown .dropdown-item {
  height: 52px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 16px 20px;
}
.daterange-dropdown .dropdown-item:hover {
  background: #cdd9fe !important;
}
.correction-table {
  width: 100%;
  font-family: Inter;
  font-size: 14.9px;
  border-collapse: separate;
  border-spacing: 0 10px;
}
.correction-table .correction-table-heading {
  font-weight: 700 !important;
  background-color: #f9f9f9 !important;
}
.correction-table .correction-table-heading,
.correction-table-cell {
  padding: 10px 20px;
  border-bottom-width: 0px !important;
  vertical-align: middle;
  height: 49.55px !important;
  border: 1.06px solid #e4e4e4 !important;
  border-left: none !important;
  border-right: none !important;
  font-size: 14.9px;
  font-weight: 400;
  line-height: 22.35px;
  color: #727272 !important;
  width: calc(100% / 5);
}

.time-frame-section {
  margin-right: 2rem !important;
}
.time-frame-section div img {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f3f2f8 !important;
  color: #54536e;
  font-size: 13.593px;
  font-weight: 600;
  border-radius: 5px !important;
  flex-shrink: 0;
  cursor: pointer;
}
.correction-table tbody th:first-child,
td:first-child {
  color: #000000 !important;
  border-left: 1.06px solid #e4e4e4 !important;
  border-radius: 4px;
}

.correction-table tbody th:last-child,
td:last-child {
  border-right: 1.06px solid #e4e4e4 !important;
  border-radius: 4px;
}

.correction-table thead th:first-child,
td:first-child {
  border-left: 1.06px solid #e4e4e4 !important;
  border-radius: 4px;
}

.correction-table thead th:last-child,
td:last-child {
  border-right: 1.06px solid #e4e4e4 !important;
  border-radius: 4px;
}

.daterange-dropdown button {
  padding: 8px 16px !important;
  --bs-btn-bg: #fff !important;
  --bs-btn-hover-bg: none !important;
  --bs-btn-active-bg: none !important;
  --bs-btn-border-color: #0343fa !important;
  --bs-btn-active-border-color: #0343fa !important;
  --bs-btn-hover-border-color: #0343fa !important;
  border-radius: 4px !important;
  color: #0343fa;
  --bs-btn-hover-color: #0343fa !important;
  --bs-btn-active-color: #0343fa !important;
  font-size: 14px;
  font-weight: 600;
}

.daterange-dropdown button::after {
  content: none !important;
}
