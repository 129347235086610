.heading-container .heading {
  font-size: 20px;
  font-weight: 700;
  line-height: 24.2px;
  color: #1c1d22;
}

.heading-container .label {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  color: #85878d;
}

.user-search-input {
  width: 100% !important;
  max-width: 238px !important;
}

.user-search-input .input-group {
  border: 1px solid #8a8a8a !important;
  border-radius: 4px !important;
}

.user-search-input .form-control {
  height: 40px !important;
  background: #ffffff !important;
}

.user-search-input .input-group-text {
  background: #ffffff !important;
}

.assignment-btn {
  height: 40px !important;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #0343fa !important;
  background: #ffffff !important;
}

.assignment-btn span {
  color: #0343fa !important;
  font-size: 14px !important;
}

.primary-btn {
  background: #0343fa !important;
}

.primary-btn span {
  color: #ffffff !important;
}

.page-dropdown {
  font-size: 12.77px;
  font-weight: 500;
  line-height: 17.88px;
  color: #1c1d22;
}

/* Modal Styles */
.modal {
  transition: all 0.3s ease-in-out;
  scrollbar-width: none;
  /* For Firefox */
  overflow: hidden;
  /* Prevents overflow outside modal */
}

.modal-dialog {
  max-width: 800px;
  /* Modal width */
  width: 100%;
  /* Ensure it doesn't exceed the screen */
  margin: auto;
  /* Center horizontally */
  height: auto;
  /* Adjust height to content */
  top: 10%;
  /* Center vertically */
  transform: translateY(-50%);
  /* Adjust for perfect centering */
  margin: auto;
}

.page-dropdown select {
  height: 39.03px;
  border-radius: 4px;
  border: 1px solid #1c1d22;
  padding: 8.52px 12.77px;
}

.status-filter {
  position: relative;
}

.status-popup {
  position: absolute;
  top: 100%;
  /* Adjust as necessary */
  left: 0;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  /* Ensure it appears above other elements */
  width: 150px;
  /* Set width as needed */
}

.status-option {
  padding: 8px 12px;
  cursor: pointer;
}

.status-option:hover {
  background-color: #f0f0f0;
  /* Highlight on hover */
}

.tab-btn {
  background-color: transparent !important;
  /* No background */
  color: #007bff !important;
  /* Primary text color */
  border: none !important;
  /* No border */
  padding: 8px 16px !important;
  /* Padding */
  cursor: pointer !important;
  /* Pointer cursor */
  transition: color 0.3s, border-bottom 0.3s !important;
  /* Transition for smooth effect */
  position: relative !important;
  /* Position relative for the pseudo-element */
}

.tab-btn span {
  color: #6a7179 !important;
}

.tab-btn::after {
  content: "" !important;
  /* Create a pseudo-element for the border */
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  bottom: -2px !important;
  /* Position it below the button */
  height: 2px !important;
  /* Height of the border */
  background-color: transparent !important;
  /* Default to transparent */
  transition: background-color 0.3s !important;
  /* Smooth transition */
}

.tab-btn:hover {
  color: #0056b3;
  /* Darker primary color on hover */
}

.active-btn span {
  color: #007bff !important;
  /* Active button text color */
}

.active-btn::after {
  background-color: #007bff !important;
  /* Active border color */
}

hr {
  margin: 0px;
  margin-bottom: 1rem;
}

.maximized .modal-dialog {
  top: 1%;
  width: 98%;
  /* Full width when maximized */
  height: 100%;
  /* Full height when maximized */
  margin: 0;
  /* No margin when maximized */
  max-width: none;
  /* Remove max-width restriction */
  margin: auto;
}

.maximized .modal-content {
  height: auto;
  /* Full height of content */
  border-radius: 8px;
  /* No border radius when maximized */
}

.maximized .modal-body {
  max-height: calc(95vh - 120px);
  /* Adjust max height based on header/footer height */
  overflow-y: auto;
  /* Enable vertical scrolling */
  scrollbar-width: none;
}

/* Header and Body Styles */
.modal-header {
  position: sticky;
  /* Makes the header sticky */
  top: 0;
  /* Sticks to the top */
  background-color: white;
  /* Background color to avoid transparency issues */
  z-index: 10;
  /* Ensure it appears above the content */
  padding: 10px;
  /* Adjust padding as needed */
  border-bottom: 1px solid #dee2e6;
  /* Optional: Add a bottom border */
}

.modal-body {
  max-height: calc(80vh - 120px);
  /* Adjust max height based on header/footer height */
  overflow-y: auto;
  /* Enable vertical scrolling */
  scrollbar-width: none;
}

.required-star {
  color: red;
}

/* Input Styles */
.custom-input-title {
  height: 50px;
  /* Small height */
  width: 60%;
  /* Default width */
  margin-bottom: 20px;
  background-color: rgb(243, 245, 250);
}

.custom-input {
  height: 50px;
  /* Small height */
  width: 60%;
  /* Default width */
  margin-bottom: 20px;
  /* Add margin to separate from next label */
  background-color: rgb(243, 245, 250);
}

.custom-date-input {
  height: 50px;
  /* Small height */
  width: 60%;
  /* Default width */
  margin-bottom: 20px;
  /* Add margin to separate from next label */
  background-color: rgb(243, 245, 250);
}

.custom-textarea {
  height: 100px;
  /* Height for textarea */
  width: 60%;
  /* Default width */
  margin-bottom: 20px;
  /* Add margin to separate from next label */
}

.custom-fileupload {
  height: 90px;
  /* Small height */
  width: 60%;
  /* Default width */
  margin-bottom: 20px;
  /* Add margin to separate from next label */
}

/* Placeholder Styles */
.cstm-search::placeholder {
  position: absolute;
  left: 20px !important;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.78px;
  color: #53545c;
  font-family: "Inter";
}

input {
  height: 45px !important;
  background: none !important;
}

.cstm-search {
  width: 360px !important;
}

/* span {
  background: none !important;
} */

/* Button Styles */
.proceed-btn {
  text-wrap: nowrap;
}

.assigned-btn {
  text-wrap: nowrap;
  color: #bdbdbd !important;
  background: #eeeeee !important;
  border: #bdbdbd !important;
}

.assigned-btn:hover {
  text-wrap: nowrap;
  background: #cdd9fe !important;
  color: #0343fa !important;
  border: 1px solid #0343fa !important;
}

.success-modal .modal-content {
  height: auto;
  border-radius: 8px;
  width: 60%;
  align-items: center;
  margin: auto;
}

.success-modal .cstm-img {
  margin: 60px;
  margin-bottom: 35px;
}

/* Column Styles */
.cstm-span-col {
  text-align: end;
  width: 100%;
  margin-top: 10px;
  line-height: 35px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .custom-input,
  .custom-date-input,
  .custom-textarea,
  .custom-input-title,
  .custom-fileupload,
  .cohort-card {
    width: 100% !important;
    /* Full width on smaller screens */
    height: auto;
  }

  .cstm-search {
    width: 315px !important;
  }

  /* Change button to an icon */
  .browse-label {
    display: none !important;
    /* Hide text label */
  }

  .browse-icon {
    display: inline-block;
    /* Show icon */
    cursor: pointer;
    padding: 5px;
    /* Adjust padding as needed */
    border: 2px solid #007bff;
    /* Style as needed */
    border-radius: 0.5rem;
    /* Match original button style */
    color: #007bff;
    /* Match color */
    font-size: x-large;
  }

  .modal-footer {
    display: block;
    padding: 15px 0px;
  }

  .proceed-btn {
    text-wrap: wrap;
  }

  .success-modal .modal-content {
    width: 80%;
    margin: auto;
  }

  .success-modal .w-100 {
    display: flex;
    /* Use flexbox for row layout */
    flex-direction: column;
    /* Stack items vertically */
    align-items: center;
    /* Center items horizontally */
  }

  .cstm-span-col {
    text-align: center;
    /* Center text on smaller screens */
    line-height: 30px;
    /* Adjust line height for smaller screens */
    margin: 0;
    /* Reset margin for better alignment */
  }

  .success-modal .cstm-img {
    margin: 30px;
    /* Reduce margin on smaller screens */
    margin-bottom: 20px;
    /* Reduce bottom margin */
  }

  .col-md-12 {
    width: 100%;
    /* Ensure columns take full width */
  }
}

@media (min-width: 769px) {
  .browse-icon {
    display: none;
    /* Hide icon on larger screens */
  }

  .browse-label {
    display: inline-block;
    /* Show text label */
  }
}

.search-cohort-input {
  width: 100% !important;
  max-width: 238px !important;
}

.search-cohort-input .input-group {
  border: 1px solid #8a8a8a !important;
  border-radius: 4px !important;
}

.search-cohort-input .form-control {
  height: 40px !important;
  background: #ffffff !important;
}

.search-cohort-input .input-group-text {
  background: #ffffff !important;
}

.cohort-card {
  width: auto;
  margin-right: 0.5rem !important;
  height: auto;
  border: 1px solid #8a8a8a !important;
  border-radius: 4px;
}

.cohort-selected-card {
  background: #f2f2f2 !important;
}

.cohort-card .icon {
  background: #fff4e080;
}

.cohort-card .card-heading,
.course-heading {
  font-size: 14px;
  font-weight: 400;
}

.cohort-card .title,
.cohort-details .title,
.course-name {
  font-size: 16px;
  font-weight: 700;
}

.cohort-card .card-divider {
  border: 0;
  border-top: 0.42px solid #0e0e14;
  width: 100%;
}

.cohort-card .cohort-detail {
  color: #54536e !important;
}

.cohort-card input[type="checkbox"],
.learner-table input[type="checkbox"] {
  width: 18px;
  height: 18px !important;
  appearance: none;
  -webkit-appearance: none;
  display: flex;
  align-content: center;
  justify-content: center;
  border: 2px solid #0343fa !important;
  border-radius: 4px !important;
  cursor: pointer;
}

.cohort-card input[type="checkbox"]:checked,
.learner-table input[type="checkbox"]:checked {
  -webkit-appearance: auto;
  cursor: pointer;
}

.table-container {
  height: auto;
  overflow-y: auto;
  scrollbar-width: none;
}

.table-container::-webkit-scrollbar {
  display: none;
}

.learner-table {
  width: 100%;
  font-family: Inter;
  font-size: 14.9px;
  border-collapse: separate;
  border-spacing: 0 10px;
}

.learner-table .user-heading {
  font-weight: 700 !important;
  color: #000000 !important;
  font-size: 15px;
  vertical-align: middle;
}

.learner-table .user-heading,
.user-cell {
  background: white !important;
  padding: 2px 7px !important;
  border-bottom-width: 0px !important;
  vertical-align: middle;
  height: 40px !important;
  border: 1.06px solid #ececec !important;
  border-left: none !important;
  border-right: none !important;
  font-size: 14px;
  font-weight: 400;
  color: #000000 !important;
}

.learner-table .view-detail {
  color: #0538ca !important;
  cursor: pointer;
}
.cohort-details-assignment {
  width: fit-content;
  height: fit-content;
}
