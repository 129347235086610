.table-container {
  max-height: 50vh; 
  height: auto; 
  overflow-y: auto;
  scrollbar-width: none; 
}

.table-container::-webkit-scrollbar {
  display: none;
}

@media (max-width: 1024px) {
  .table-container {
    max-height: 40vh; 
  }
  .assignment-table .table-heading,
  .table-cell {
    padding: 2px 7px !important;
  }
}

@media (max-width: 768px) {
  .table-container {
    max-height: 30vh; 
  }
  .assignment-table .table-heading,
  .table-cell {
    padding: 2px 7px !important;
  }
}
.assignment-table {
  width: 100%;
  font-family: Inter;
  font-size: 14.9px;
  border-collapse: separate;
  border-spacing: 0 10px;
}
.assignment-table .table-heading {
  font-weight: 700 !important;
  background-color: #f9f9f9 !important;
}
.assignment-table .table-heading,
.table-cell {
  padding: 10px 20px;
  border-bottom-width: 0px !important;
  vertical-align: middle;
  height: 49.55px !important;
  border: 1.06px solid #e4e4e4 !important;
  border-left: none !important;
  border-right: none !important;
  font-size: 14.9px;
  font-weight: 400;
  line-height: 22.35px;
  color: #727272 !important;
  width: calc(100% / 5);
}
.assignment-table .title-column {
  max-width: 204.67px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.assignment-table tbody th:first-child,
td:first-child {
  color: #000000 !important;
  border-left: 1.06px solid #e4e4e4 !important;
  border-radius: 4px;
}

.assignment-table tbody th:last-child,
td:last-child {
  border-right: 1.06px solid #e4e4e4 !important;
  border-radius: 4px;
}

.assignment-table thead th:first-child,
td:first-child {
  border-left: 1.06px solid #e4e4e4 !important;
  border-radius: 4px;
}

.assignment-table thead th:last-child,
td:last-child {
  border-right: 1.06px solid #e4e4e4 !important;
  border-radius: 4px;
}

.assignment-table .assignment-actions-dropdown {
  border: 1px solid #e4e4e4;
  box-shadow: 0px 6px 12px 0px #0000001f;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #1c1d22;
}
.assignment-actions-dropdown .dropdown-item {
  height: 52px !important;
  display: flex;
  align-items: center;
  justify-items: center;
}
.assignment-actions-dropdown .dropdown-item:hover {
  background: #cdd9fe !important;
}
.label-success {
  background: #f0feed;
  border-radius: 38.32px;
  width: fit-content;
  padding: 6px 10px;
}
.label-primary {
  background: #edf5fe;
  border-radius: 38.32px;
  width: fit-content;
  padding: 6px 10px;
}
.label-danger {
  background: #feeded;
  border-radius: 38.32px;
  width: fit-content;
  padding: 6px 10px;
}
.label-warning {
  background: #FFF4E0;
  border-radius: 38.32px;
  width: fit-content;
  padding: 6px 10px;
}
.label-success span {
  background: #259800 !important;
  color: #259800;
}
.label-primary span {
  background: #3083ff !important;
  color: #3083ff;
}
.label-danger span {
  background: #dc2626 !important;
  color: #dc2626;
}
.label-success label {
  background: #f0feed;
  color: #259800;
}
.label-primary label {
  background: #edf5fe;
  color: #3083ff;
}
.label-danger label {
  background: #feeded;
  color: #dc2626;
}

.label-warning span {
  background: #996800 !important;
  color: #996800;
}
.label-warning label {
  background: #FFF4E0;
  color: #996800;
}
