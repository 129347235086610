.setting-account-tittle {
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.36px;
    letter-spacing: 0.0125em;
    text-align: left;
    color: #1C1D22;
}

.setting-upload-signature {
    max-width: 500px;
    width: 100%;
    margin-top: 35px;
}