.otpContainer {
    display: flex;
    gap: 25px;
    /* Space between each input */
}

.otpInput {
    width: 75px;
    height: 40px;
    text-align: center;
    font-size: 36px;
    border: none;
    font-weight: 400;
    border-bottom: 2px solid #ccc;
    outline: none;
    transition: border-bottom-color 0.3s ease;
}

.otpInput:focus {
    border-bottom-color: #007bff;
    /* Change the color on focus */
}