.heading {
    font-size: 20px;
    font-weight: 700;
    line-height: 24.2px;
    color: #1c1d22;
}

.usersDropdown {
    border: 1px solid #e4e4e4;
    box-shadow: 0px 6px 12px 0px #0000001f;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #1c1d22;
}

.dropdownItem {
    height: 52px !important;
    display: flex;
    align-items: center;
    justify-items: center;
}

.dropdownItem:hover {
    background: #cdd9fe !important;
}

.flexContainer {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 16px;
    margin-bottom: 12px;
}

.iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 40px;
    border-radius: 5px;
}

.iconContainer span {
    font-weight: 600;
}

.deleteIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 5px;
}

/* Hover effect */
.iconContainer:hover,
.deleteIcon:hover {
    background-color: #CDD9FE;
    /* Change this to any hover color */
    cursor: pointer;
}