.setting-tittle {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    letter-spacing: 0.0125em;
    text-align: left;
    color: #1C1D22;
}

.setting-contact {
    /* height: 412px; */
    gap: 0;
    border-radius: 4px 0 0 0;
    border: 1px solid #ECECEC;
    opacity: 1;
}

@media (max-width: 1200px) {
    .setting-contact .setting-contact-header {
        width: 884px;
    }
}

@media (max-width: 992px) {
    .setting-contact .setting-contact-header {
        width: 450px;
    }
}

@media (max-width: 768px) {
    .setting-contact .setting-contact-header {
        width: 350px;
    }
}

@media (max-width: 576px) {
    .setting-contact .setting-contact-header {
        width: 300px;
    }
}

@media (max-width: 320px) {
    .setting-contact .setting-contact-header {
        width: 250px;
        height: auto;
    }
}


.setting-contact-header {
    background: #F9F9F9;
    padding: 12px 16px 12px 16px;
    border-radius: 4px 4px 0px 0px;
    border: 1px 0px 0px 0px;
    opacity: 0px;
    height: 50px;
    border: 1px solid #ECECEC;
}

.setting-contact-header p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    letter-spacing: 0.0125em;
    text-align: center;
    margin: 0;
}

.setting-contact-details {
    background: white;
}

.setting-contact-details .text {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    letter-spacing: 0.0125em;
    text-align: left;
}