.login-logo {
    height: 40px;
    width: 160px;
}

.eye-icon {
    color: #6E7079;
}

.login-help-text {
    font-size: 14px;
}

.help-section-text {
    font-size: 14px;
    padding-top: 56px;
}

.text-decoration-none {
    text-decoration: none;
}