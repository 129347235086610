.heading-container .heading {
    font-size: 20px;
    font-weight: 700;
    line-height: 24.2px;
    color: #1c1d22;
}

.tab-btn {
    background-color: transparent !important;
    color: #007bff !important;
    border: none !important;
    padding: 8px 16px !important;
    cursor: pointer !important;
    transition: color 0.3s, border-bottom 0.3s !important;
    position: relative !important;
}

.tab-btn span {
    color: #6a7179 !important;
}

.tab-btn::after {
    content: "" !important;
    position: absolute !important;
    left: 0 !important;
    right: 0 !important;
    bottom: -2px !important;
    height: 2px !important;
    background-color: transparent !important;
    transition: background-color 0.3s !important;
}

.tab-btn:hover {
    color: #0343FA;
}

.active-btn span {
    color: #0343FA !important;
}

.active-btn::after {
    background-color: #0343FA !important;
}