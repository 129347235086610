.css-6wcx0a-MuiResponsiveChart-container {
  width: 100%;
  height: 200px;
}

.dashboard-container {
  margin-bottom: 5rem !important;
}

.css-p1sr87-MuiGauge-referenceArc {
  fill: #0343fa;
}

.css-1y5vrnu-MuiButtonBase-root-MuiCheckbox-root {
  color: #0343fa !important;
}

.MuiBarElement-root .MuiBarElement-series-pvId {
  width: 32.04px !important;
  height: 55.02px !important;
  border-radius: 0px 0px 6.96px 6.96px;
}

.header-search-input::placeholder {
  position: absolute;
  left: 4.5rem !important;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.78px;
  color: #53545c;
}

.username {
  font-size: 20px;
  color: #000000;
  font-weight: 700;
}

.heading {
  font-size: 13.93px;
  font-weight: 500;
  line-height: 16.86px;
  color: #000000;
  margin: 12px 0;
}

.productivity-card {
  position: relative;
  top: 130px;
}

@media (max-width: 768px) {
  .productivity-card {
    top: 20px;
    padding: 0 15px;
  }

  .d-lg-flex {
    flex-direction: column;
    align-items: flex-start;
  }

  .mt-lg-0 {
    margin-top: 1rem;
  }

  .productivity-label {
    font-size: 1rem;
  }

  .productivity-sub-label {
    font-size: 0.875rem;
  }
}


.productivity-header {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.productivity-label {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: center;
  color: #8B8D97;
}

.productivity-sub-label {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
}

.card-container {
  padding: 15px 21px;
  border: 1px solid transparent;
  border-color: #D3D3D3;
  border-radius: 20px;
  box-shadow: 0px 4px 6px 0px #00000017;
}

.course-educator-container {
  padding: 15px 20px;
  border: 1px solid transparent;
  border-color: #D3D3D3;
  border-radius: 20px;
  box-shadow: 0px 4px 6px 0px #00000017;
  height: 200px;
  width: 31%;
}

.course-supervisor-container {
  padding: 15px 20px;
  border: 1px solid transparent;
  border-color: #D3D3D3;
  border-radius: 20px;
  box-shadow: 0px 4px 6px 0px #00000017;
  height: 200px;
  width: 48%;
}

.course-container {
  padding: 15px 45px;
  border: 1px solid transparent;
  border-color: #D3D3D3;
  border-radius: 20px;
  box-shadow: 0px 4px 6px 0px #00000017;
  height: 250px;
}

@media (max-width: 1399px) {
  .sidebar {
    flex: 0 0 auto;
    width: 25%;
  }
}

@media (max-width: 1399px) {
  .screen-content {
    flex: 0 0 auto;
    width: 75%;
  }
}

@media (max-width: 1399px) {
  .sidebar {
    flex: 0 0 auto;
    width: 25%;
  }
}

@media (max-width: 767px) {
  .screen-content {
    flex: 0 0 auto;
    width: 100%;
  }
}

@media (max-width: 1399px) {

  .card-content,
  .listitems-container {
    flex: 0 0 auto;
    width: 100%;
    margin-top: 1rem !important;
  }
}

.css-rerwiy-MuiGauge-container text {
  fill: rgb(3, 67, 250) !important;
}

.css-b9rdri-MuiGauge-referenceArc {
  fill: rgb(3, 67, 250) !important;
}