.assigned-container,
.completed-container,
.clock-container {
    display: flex;
    align-items: center;
    border-radius: 5px; /* Rounded corners */
    margin-bottom: 10px;
    inline-size: max-content;

}

.assigned,
.completed,
.clock {
    padding: 5px;
    font-size: 2.5rem;
    margin-right: 5px;
    border-radius: 50%;
}

.assigned {
    background-color: #f8d7da;
}

.completed {
    background-color: #F1FFF1;
}

.clock {
    background-color: #EFEFEF;
}

.view-button {
    background: #CDD9FE;
    border: none;
    width: inherit;
    height: -webkit-fill-available;
    transition: none;
}

.view-button:hover {
    background: #CDD9FE;
    border: none;
}

.card-header,
.card-footer {
    background: none;
}

.cstm-text {
    color: #8A8A8A;
}

/* Responsive Styles */
@media (min-width: 320px) {
    .assigned,
    .completed,
    .clock {
        font-size: 2rem; /* Smaller font size for small screens */
    }
}

@media (min-width: 640px) {
    .assigned,
    .completed,
    .clock {
        font-size: 2.5rem; /* Default font size */
    }
}

@media (min-width: 768px) {
    .assigned,
    .completed,
    .clock {
        font-size: 3rem; /* Larger font size for medium screens */
    }

    .view-button {
        padding: 7px; /* Add some padding for larger screens */
    }
}

@media (min-width: 1024px) {
    .assigned,
    .completed,
    .clock {
        font-size: 3.5rem; /* Even larger font size for large screens */
    }
}

@media (min-width: 1280px) {
    .assigned,
    .completed,
    .clock {
        font-size: 4rem; /* Increase font size for extra-large screens */
    }

    .view-button {
        padding: 7px; /* More padding for extra-large screens */
    }
}

@media (min-width: 1536px) {
    .assigned,
    .completed,
    .clock {
        font-size: 4.5rem; /* Max font size for 2xl screens */
    }
}
