.usersDropdown {
    border: 1px solid #e4e4e4;
    box-shadow: 0px 6px 12px 0px #0000001f;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #1c1d22;
}

.dropdownItem {
    height: 36px !important;
    display: flex;
    align-items: center;
    justify-items: center;
    padding: 20px;
    width: 230px;
}

.dropdownDisableItem {
    background: #ECECEC !important;
}

.dropdownItem:hover {
    background: #cdd9fe !important;
}

.filterText {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: start;
}

.checkbox {
    margin-right: 20px;
    cursor: pointer;
}

.checkbox:checked {
    background-color: #007bff;
}