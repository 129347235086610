.heading {
    font-size: 30px;
    font-weight: 500;
}

.card {
    min-width: 500px;
    max-width: 500px;
    min-height: 500px;
}

.description {
    font-size: 16px;
    font-weight: 400;
}

.stepWrapper {
    position: relative;
    width: 100%;
    height: 200px;
    overflow: hidden;
}

.stepContent {
    position: absolute;
    width: 100%;
    transition: transform 0.5s ease-in-out;
}

.next .stepContent {
    transform: translateX(100%);
}

.prev .stepContent {
    transform: translateX(-100%);
}

.next .stepContent:nth-child(1) {
    transform: translateX(-100%);
}

.prev .stepContent:nth-child(1) {
    transform: translateX(100%);
}

.stepNavigation {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}