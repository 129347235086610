/* Container styling for full page height */
.error-page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 90vh; /* Full viewport height */
    padding: 20px;
    box-sizing: border-box;
}

/* Flexbox layout for error content */
.error-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    width: 100%;
    padding: 0 40px;
    flex-grow: 1; /* Ensures content takes up available space */
}

/* Styling for the message section */
.error-message {
    max-width: 600px;
    margin-right: 20px;
}

/* Styling for the text inside the message */
.error-message h1,
.error-message h2,
.error-message p {
    text-align: left;
    margin-bottom: 20px;
    font-family: Inter;
    color: #000;
}

.error-message h1 {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
}

.error-message h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
}

.error-message p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

/* Styling for the button */
.home-button {
    background-color: #0343FA;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
}

.home-button:hover {
    background-color: #0056b3;
}

/* Styling for the error graphic */
.error-graphic img {
    width: 450px;
    height: auto;
    background: transparent !important;
}

/* Styling for the footer */
footer {
    width: 100%;
    text-align: center;
    padding: 20px;
    background-color: #fff;
    border-top: 1px solid #eaeaea;
    font-size: 16px;
    color: #777;
    position: absolute;
    bottom: 0;
    left: 0;
}


/* Media query for screens smaller than 750px */
@media (max-width: 750px) {
    .error-content {
        flex-direction: column;
        text-align: center;
        padding: 0 20px;
    }

    .error-message {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .error-graphic img {
        width: 100%;
        max-width: 250px;
    }
}

/* Media query for screens smaller than 500px */
@media (max-width: 500px) {
    .error-message h1 {
        font-size: 28px;
    }

    .error-message h2 {
        font-size: 20px;
    }

    .error-message p {
        font-size: 14px;
    }

    .home-button {
        padding: 8px 16px;
    }

    .error-graphic img {
        max-width: 200px;
    }
}
