/* Modal Styles */
.modal {
    transition: all 0.3s ease-in-out;
    scrollbar-width: none;
    /* For Firefox */
    overflow: hidden;
    /* Prevents overflow outside modal */
}

.modal-dialog {
    max-width: 800px;
    /* Modal width */
    width: 100%;
    /* Ensure it doesn't exceed the screen */
    margin: auto;
    /* Center horizontally */
    height: auto;
    /* Adjust height to content */
    top: 10%;
    /* Center vertically */
    transform: translateY(-50%);
    /* Adjust for perfect centering */
    margin: auto;
}

.modal-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24.2px;
    text-align: left;
}

.modal-content {
    height: auto;
    /* Set a maximum height */
    overflow-y: auto;
    /* Enable vertical scrolling */
    border-radius: 8px;
    /* Optional: adjust border radius */
}

.maximized .modal-dialog {
    top: 1%;
    width: 98%;
    /* Full width when maximized */
    height: 100%;
    /* Full height when maximized */
    margin: 0;
    /* No margin when maximized */
    max-width: none;
    /* Remove max-width restriction */
    margin: auto;
}

.maximized .modal-content {
    height: auto;
    /* Full height of content */
    border-radius: 8px;
    /* No border radius when maximized */
}

.maximized .modal-body {
    max-height: calc(95vh - 120px);
    /* Adjust max height based on header/footer height */
    overflow-y: auto;
    /* Enable vertical scrolling */
    scrollbar-width: none;
}

/* Header and Body Styles */
.modal-header {
    position: sticky;
    /* Makes the header sticky */
    top: 0;
    /* Sticks to the top */
    background-color: white;
    /* Background color to avoid transparency issues */
    z-index: 10;
    /* Ensure it appears above the content */
    padding: 25px;
    /* Adjust padding as needed */
    border-bottom: 1px solid #dee2e6;
    /* Optional: Add a bottom border */
}

.modal-body {
    max-height: calc(80vh - 120px);
    /* Adjust max height based on header/footer height */
    overflow-y: auto;
    /* Enable vertical scrolling */
    scrollbar-width: none;
}

.required-star {
    color: red;
}

/* Input Styles */
.custom-input-title {
    height: 50px;
    /* Small height */
    width: 100%;
    /* Default width */
    margin-bottom: 20px;
    background-color: rgb(243, 245, 250);
}

.css-1dune0f-MuiInputBase-input-MuiOutlinedInput-input {
    height: 0.75rem !important;
}

.css-jvxs11-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    height: 3.2em !important;
}

.css-1rlry63-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    color: black !important;
}

.custom-input {
    height: 50px;
    /* Small height */
    width: 60%;
    /* Default width */
    margin-bottom: 20px;
    /* Add margin to separate from next label */
    background-color: rgb(243, 245, 250);
}

.custom-date-input {
    height: 50px;
    /* Small height */
    width: 45%;
    /* Default width */
    margin-bottom: 20px;
    /* Add margin to separate from next label */
    background-color: rgb(243, 245, 250);
}

.custom-textarea {
    height: 100px;
    /* Height for textarea */
    width: 100%;
    /* Default width */
    margin-bottom: 20px;
    /* Add margin to separate from next label */
}

.custom-fileupload {
    height: 90px;
    /* Small height */
    width: 100%;
    /* Default width */
    margin-bottom: 20px;
    /* Add margin to separate from next label */
}

.custom-fileupload .col-4 {
    width: 20%;
}

.custom-fileupload .col-3 {
    width: 50%;
}

.custom-fileupload .col-5 {
    width: 20%;
}

.custom-fileupload .col-5 .browse-label {
    padding: 0px 20px !important;
}

/* Placeholder Styles */
.cstm-search::placeholder {
    position: absolute;
    left: 20px !important;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.78px;
    color: #53545c;
    font-family: "Inter";
}

input {
    height: 45px !important;
    background: none !important;
}

.cstm-search {
    width: 360px !important;
}

/* span {
    background: none !important;
} */

/* Button Styles */
.proceed-btn {
    text-wrap: nowrap;
}

.assigned-btn {
    text-wrap: nowrap;
    color: #BDBDBD !important;
    background: #EEEEEE !important;
    border: #BDBDBD !important;
}

.assigned-btn:hover {
    text-wrap: nowrap;
    background: #CDD9FE !important;
    color: #0343FA !important;
    border: 1px solid #0343FA !important;
}

.success-modal .modal-content {
    height: auto;
    border-radius: 8px;
    width: 60%;
    align-items: center;
    margin: auto;
}

.success-modal .cstm-img {
    margin: 60px;
    margin-bottom: 35px;
}

.cstm-lh {
    line-height: 45px;
}

.cstm-lh-upload {
    line-height: 35px;
}

.txt-data {
    padding-left: 10px;
}

/* Column Styles */
.cstm-span-col {
    text-align: end;
    width: 100%;
    margin-top: 10px;
    line-height: 35px;
}

.download-button,
.download-button:hover {
    text-wrap: nowrap;
    background-color: white;
    border: #007bff 1px solid;
    color: #007bff;
    width: 100%;
}

.css-5c1adp-MuiContainer-root {
    overflow-y: hidden;
}

.txt-lbl {
    font-weight: 900 !important;
}

.flotting-btn {
    position: absolute;
    /* Default position for larger screens */
    top: 20px;
    /* Position from the top */
    right: 20px;
    /* Position from the right */
    z-index: 1000;
    /* Keep it on top of other elements */
    width: min-content;
    /* Adjust width to fit content */

}

.download-button {
    width: 100%;
}

.error-span {
    color: red;
    padding-left: 40px;

}

/* Responsive Styles */
@media (max-width: 768px) {

    .custom-input,
    .custom-date-input,
    .custom-textarea,
    .custom-input-title,
    .custom-fileupload,
    .cohort-card {
        width: 100% !important;
        /* Full width on smaller screens */
        height: auto;
    }

    .cstm-search {
        width: 315px !important;
    }

    /* Change button to an icon */
    .browse-label {
        display: none !important;
        /* Hide text label */
    }

    .browse-icon {
        display: inline-block;
        /* Show icon */
        cursor: pointer;
        padding: 5px;
        /* Adjust padding as needed */
        border: 2px solid #007bff;
        /* Style as needed */
        border-radius: 0.5rem;
        /* Match original button style */
        color: #007bff;
        /* Match color */
        font-size: x-large;
    }

    .modal-footer {
        display: block;
        padding: 15px 0px;
    }

    .proceed-btn {
        text-wrap: wrap;
    }

    .success-modal .modal-content {
        width: 80%;
        margin: auto;
    }

    .success-modal .w-100 {
        display: flex;
        /* Use flexbox for row layout */
        flex-direction: column;
        /* Stack items vertically */
        align-items: center;
        /* Center items horizontally */
    }

    .cstm-span-col {
        text-align: center;
        /* Center text on smaller screens */
        line-height: 30px;
        /* Adjust line height for smaller screens */
        margin: 0;
        /* Reset margin for better alignment */
    }

    .success-modal .cstm-img {
        margin: 30px;
        /* Reduce margin on smaller screens */
        margin-bottom: 20px;
        /* Reduce bottom margin */
    }

    .col-md-12 {
        width: 100%;
        /* Ensure columns take full width */
    }

    .flotting-btn {
        position: absolute;
        /* Change to fixed positioning */
        bottom: 20px;
        top: 20px;
        /* Position from the bottom */
        right: 10px;
        /* Adjust right position for smaller screens */
        width: min-content;
        height: fit-content;
        /* Auto width to fit the buttons */
    }

    .download-button {
        width: 100%;
        /* Full width for buttons on smaller screens */
        margin-top: 5px;
        /* Adjust margin for spacing */
    }
}

@media (min-width: 769px) {
    .browse-icon {
        display: none;
        /* Hide icon on larger screens */
    }

    .browse-label {
        display: inline-block;
        /* Show text label */
    }

    .flotting-btn {
        top: 50px;
        /* Keep top position */
        right: 20px;
        /* Keep right position */
    }

    .download-button {
        width: 100%;
        /* Default button width */
        margin-top: 10px;
        /* Keep default margin */
    }
}