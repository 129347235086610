.label-resubmission {
  background: #fff4e0 !important;
  border-radius: 38.32px;
  width: fit-content;
  padding: 6px 10px;
}
.label-resubmission span {
  background: #996800 !important;
  color: #996800;
}

.label-resubmission label {
  background: #fff4e0 !important;
  color: #996800;
}

.rating-modal .signature-text {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 13.625px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.437px; /* 150% */
  letter-spacing: 0.17px;
}
.rating-modal .success-popup-text{
    margin-bottom: 0.5rem !important;
}