.feedback-summary {
  margin-bottom: 4rem !important;
}
.feedback-summary .marker-heading {
  color: #1c1d22;
  font-size: 20px;
  font-weight: 400;
}
.feedback-summary .assignment-heading {
  color: #1c1d22;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}
.feedback-summary .assignment-detail {
  overflow: hidden;
  color: #727272;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.feedback-summary .resubmit-label {
  color: #0343fa;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-right: 2rem !important;
}
.question-comp,
.answer-comp,
.grade-comp {
  border-radius: 4px;
  border: 1px solid #ececec;
  background: #fff;
  height: 500px;
  overflow-y: auto;
}
.card-heading {
  display: flex;
  height: 49px;
  padding: 12.774px 12.774px 12.774px 16px;
  align-items: center;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 1px solid #ececec;
  background: #f9f9f9;
  overflow: hidden;
  color: #727272 !important;
  text-overflow: ellipsis;
  font-size: 16.903px;
  font-style: normal;
  font-weight: 700;
}
.feedback-comp {
  border-radius: 4px;
  border: 1px solid #ececec;
  background: #fff;
}
.total {
  height: 49px;
  padding: 12.774px 12.774px 12.774px 16px;
  border-radius: 4px 4px 0px 0px;
  background: #f9f9f9;
  overflow: hidden;
  color: #727272 !important;
  text-overflow: ellipsis;
  font-size: 14.903px;
  font-style: normal;
  border-top: 1px solid #ececec;
}
.total span {
  font-size: 16.903px;
  font-weight: 700;
}
.question-card,
.answer-card,
.grade-comp {
  color: #1c1d22;
  font-size: 14px;
  font-style: normal;
  cursor: pointer;
  font-weight: 500;
  font-family: Inter;
}
.question-card {
  height: auto !important;
}
.active-question-card {
  background: rgba(239, 241, 249, 0.6);
}
.answer-title {
  color: #727272;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 21px */
}
.answer {
  color: #727272;
}
.answer-detail,
.feedback-textarea {
  color: #727272;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.marks-section {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.25px;
}

.feedback-section .feedback-label {
  color: #1c1d22;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}
.marks-input-container {
  color: #727272 !important;
  font-weight: 700;
}
.marks-input-container .marks-input {
  color: #727272 !important;
  border: none; /* Remove border */
  padding: 0; /* Remove padding */
  width: auto; /* Adjust width as needed */
  background: transparent; /* Make background transparent */
  outline: none; /* Remove outline on focus */
  -moz-appearance: textfield; /* Hide increment/decrement buttons in Firefox */
  width: 1rem !important;
  font-weight: 700;
}

.marks-input::-webkit-inner-spin-button,
.marks-input::-webkit-outer-spin-button {
  -webkit-appearance: none; /* Hide increment/decrement buttons in Chrome */
}
.feedback-section {
  height: 250px; /* Adjust height as needed */
}

.feedback-textarea {
  width: 100%; /* Full width */
  height: 80%; /* Height should be 80% of parent container */
  border: none; /* Default no border */
  background: transparent; /* Default transparent background */
  resize: none; /* Disable resizing */
  outline: none; /* Remove outline */
  transition: border 0.3s, background 0.3s; /* Smooth transition for focus effect */
}

/* Active state styling when clicked */
.feedback-textarea.active {
  border-radius: 4px;
  border: 1px solid #ececec;
  background: #f9f9f9;
}

.levels-dropdown {
  position: absolute; /* Position the dropdown absolutely */
  z-index: 9999; /* Ensure it's above other elements */
  top: 100%; /* Position it below the dropdown toggle */
  left: 0; /* Align it with the toggle */
}
.signature-text {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.signature-bold-text {
  color: #1c1d22;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  margin: 2rem 0;
}
.upload-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  color: #0538ca !important;
}
.publish-btn,
.active-publish-btn {
  margin-top: 4rem;
  margin-bottom: 1rem;
  display: inline-flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: #eee !important;
  height: auto !important;
}
.publish-btn span,
.active-publish-btn span {
  color: #bdbdbd !important;
  font-family: Inter;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.175px;
}
.signature-section-one {
  margin-top: 1rem !important;
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}
.signature-img-text {
  color: #0a0a0a !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.08px;
}

.custom-radio {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.custom-radio input {
  appearance: none; /* Remove default styling */
  width: 20px; /* Set width */
  height: 20px !important; /* Set height */
  border: 2px solid #0538ca; /* Border color */
  border-radius: 50%; /* Make it circular */
  background-color: white; /* Background color */
  outline: none; /* Remove outline */
  cursor: pointer; /* Pointer cursor */
}

.custom-radio input:checked {
  appearance: auto;
  background-color: #0538ca; /* Background color when checked */
}
.active-publish-btn {
  background: #0343fa !important;
}

.active-publish-btn span {
  color: #fff !important;
}
.file-img {
  width: 90px;
  height: 60px;
  margin-top: 2rem;
}

.success-popup-text {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.image-container {
  display: inline-block; /* Makes the container fit the image */
  padding: 5px; /* Optional: Add some padding */
  transition: background-color 0.3s; /* Smooth transition */
  background: #CDD9FE;
  border-radius: 32px;
}

.image-container:hover {
  background: #3083ff;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.12);
}
.image-active{
  background: none !important;
  border-radius : none !important;
  box-shadow : none !important;
}
.signature-img{
  width: 111px !important;
  height: 59px !important;
}