body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pagination__button button {
  border: none !important;
}

.pagination__button button.MuiPaginationItem-rounded {
  background-color: #fff !important;
  color: #0b0d0f !important;
  font-size: 12.77px !important;
}

.pagination__button button.Mui-selected {
  background: #0343fa !important;
  color: #fff !important;
}

@media (max-width: 1000px) {

  .search-input,
  .header-search-input {
    width: 100% !important;
  }
}

.header-search-input {
  width: 330px;
}

.header-search-input .input-group {
  border: 1px solid #5782FC !important;
  border-radius: 4px !important;
  background: #F5F6FA !important;
}

.header-search-input .form-control {
  height: 48px !important
}

.sidebar .menuitem {
  padding: 8px !important;
}

.sidebar .active-menuitem {
  background: #CDD9FE !important;
  border-radius: 12px !important;
  padding: 8px !important;
}

.active-menuitem .menuitem {
  color: #0343FA !important;
}
.rdrDefinedRangesWrapper{
  display: none !important;

}
.my-modal {
  width: 60vw;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 50%;
  transform: translate(-50%, -10%);
}

.my-assignments-modal {
  width: 70vw;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 50%;
  transform: translate(-50%, -0%);
}

.move-learner-modal {
  width: 40vw;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 50%;
  transform: translate(-50%, -50%);
}