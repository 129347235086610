.preference-header {
    background: #F9F9F9;
    padding: 12px 16px 12px 16px;
    border-radius: 4px 4px 0px 0px;
    border: 1px 0px 0px 0px;
    opacity: 0px;
    height: 50px;
    border: 1px solid #ECECEC;
}

.preference-schedule {
    height: auto;
    gap: 0;
    border-radius: 4px 0 0 0;
    border: 1px solid #ECECEC;
    opacity: 1;
    display: flex;
    flex-direction: column;
}

@media (max-width: 1200px) {
    .preference-header .preference-schedule {
        width: 100%;
    }
}

@media (max-width: 992px) {
    .setting-contact .preference-schedule {
        width: 450px;
    }
}

@media (max-width: 768px) {
    .setting-contact .preference-schedule {
        width: 350px;
    }
}

@media (max-width: 576px) {
    .setting-contact .preference-schedule {
        width: 300px;
    }
}

@media (max-width: 320px) {
    .setting-contact .preference-schedule {
        width: 250px;
        height: auto;
    }
}

.schedule-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    background-color: #f6f8fa;
    border: 1px solid #e1e4e8;
    border-radius: 10px;
    width: 100%;
    max-width: 500px;
    margin: 5px 0;
}


@media (max-width: 768px) {
    .schedule-box {
        flex-direction: column;
        align-items: flex-start;
    }

    .schedule-box span {
        margin-top: 10px;
    }
}

.schedule-box span {
    display: inline-block;
    margin-right: 0.5rem;
}

input[type="time"] {
    border-radius: 4px;
    border: 1px solid #ced4da;
    padding: 8px;
    height: 25px !important;
}

.schedule-table {
    border-collapse: collapse !important;
    width: 100% !important;
}

.schedule-table th,
.schedule-table td {
    border-left: none !important;
    border-right: none !important;
    border-bottom: 1px solid #D3D3D3;
}